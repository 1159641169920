import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PrintDate from './components/PrintDate'; 
import { MainSettingsContext } from '../App.js';

export default function Print({ readonlySwitchDateTime }) {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('waiting');
    const [dates, setDates] = useState([]);
    const navigate = useNavigate();
    const client = getWebClient();

    let backButton;

    function enabledDatesGetPromise() {
        return new Promise((resolve) => {
            client.get(`/api/getenableddates`).then((response) => {
                resolve(response.data);
            });
        });
    }

    useEffect(() => {

        Promise.all([enabledDatesGetPromise()])

            .then((results) => {
                setDates(results[0]);
                setViewMode('');
            })

    }, []);

    if (Date.now() > readonlySwitchDateTime) {
        backButton = (
            <>
                <div className="col-md-12 text-center" style={{ marginTop: 30, marginBottom: 40 }}>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => window.print()}>Print</button>
                    </div>
                </div>
            </>    
        );
    } else {
        backButton = (
            <>
                <div className="col-md-12 text-center" style={{ marginTop: 30, marginBottom: 40 }}>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => window.print()}>Print</button>
                    </div>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                    </div>
                </div>
            </>
        );
    }


    if (viewMode !== '') {


        return (
            <>
                <Spinner></Spinner>
            </>
        );

    } else {

        return (
            <>

                <section className="buyer-sec ma-sec">
                    <header className="header">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src="assets/images/logo.svg" alt="" />
                            </div>
                            <div className="col-md-6">
                                <h2>Workshop Agenda</h2>
                                <p>{locationAndDates}</p>
                            </div>
                        </div>
                    </header>
                    <div className="container ma-container">
                        <div className="row">

                            {dates.map((item, index) => (
                                <PrintDate date={item} key={index}></PrintDate>
                            ))}

                            {backButton}

                        </div>
                    </div>
                </section>

            </>
        );

    }

}