import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError';
import { MainSettingsContext } from '../App.js';

export default function PartnerProfile() {

    const { locationAndDates } = useContext(MainSettingsContext);

    const { partnerid } = useParams();
    const [viewMode, setViewMode] = useState('waiting');
    const [profile, setprofile] = useState(
        {
            company: '',
            city: '',
            country: '',
            typologyId: 0,
            typology: '',
            personInCharge: '',
            telephone: '',
            email: '',
            website: '',
            description: '',
            seller: null,
            buyer: null
        }
    );
    const navigate = useNavigate();
    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/getpartnerprofile/${partnerid}`)
            .then(function (response) {
                setprofile(response.data);
                setViewMode('')
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error')
            });

    }, []);

    return (

        <>

            <section className="buyer-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="/assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>{locationAndDates}</p>
                        </div>
                    </div>
                </header>
                <div className="buyer-container">
                    <h1 className="main-heading">Operator profile</h1>
                    <div className="row">
                        <ul>
                            <li className="co-name">
                                <span className="co-btn">Company</span>
                                <span style={{ marginLeft: 10 }}>{profile.company}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">City</span>
                                <span>{profile.city}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Country</span>
                                <span>{profile.country}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Typology</span>
                                <span>{profile.typology}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Person in charge</span>
                                <span>{profile.personInCharge}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Telephone</span>
                                <span>{profile.telephone}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Email</span>
                                <span>{profile.email}</span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Website</span>
                                <span>{profile.website}</span>
                            </li>
                        </ul>
                        <div className="col-md-12 des-sec">
                            <h3>Description</h3>
                            <label>{profile.description}</label>
                        </div>
                        <div className="col-md-12 text-center">
                            <a href={`/partneragenda/${partnerid}`} className="btn btn-primary appointment-btn">Fix an appointment</a>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>

        </>
    );

}