import React, { useState, useContext } from 'react';
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner.js';
import PromptAuthenticationError from './components/PromptAuthenticationError.js';
import PromptError from './components/PromptError.js';
import { MainSettingsContext } from '../App.js';

export default function Login({ setAuthenticationToken }) {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('');

    function handleLoginClick() {

        setViewMode('waiting');

        const pin = document.getElementById('txtLoginPin').value;
        const client = getWebClient();

        client.get(`/api/gettoken/${pin}`)
            .then(function (response) {
                if (response.data.token) {
                    localStorage.setItem("authenticationToken", response.data.token);
                    setAuthenticationToken(response.data.token);
                    setViewMode('');
                } else {
                    setViewMode('authenticationerror');
                }
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }

    return (
        <>

            <section id="login-box">

                <div className="login-body">
                    <div className="header">
                        <img src="assets/images/logo.svg" alt="" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <h2 className="login-heading">Workshop Agenda</h2>
                            <p className="login-pera">{locationAndDates}</p>

                            <h3 className="login-heading">Welcome to Workshop Appointments' System</h3>
                            <p className="login-ttl">From this page you will be able to access your private area and to:</p>

                            <ul className="login-list">
                                <li>See the complete Buyers / Sellers List</li>
                                <li>Ask and fix appointments with Buyers / Sellers during the Workshop</li>
                                <li>Create, update and print your appointments' agenda.</li>
                            </ul>
                            <ul className="login-list1">
                                <li className="login-term"> <b> This private area will be daily updated </b> with new Buyers / Sellers
                                    and with confirmation of fixed appointments: please enter daily to monitor new contacts and new
                                    appointments.</li>
                                <li className="login-term mt-3">For a correct language page visualization you have to enable cookies:
                                </li>
                                <li className="login-term"><b> Good surfing! </b> </li>
                            </ul>
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="password" id="txtLoginPin" placeholder="Pin" className="form-control" />
                                    </div>
                                    <div className="col-md-12 m-auto text-center">
                                        <button type="button" id="btnLoginSignIn" className="btn btn-primary signIn" onClick={handleLoginClick}> Sign in </button>
                                    </div>
                                </div>
                            </form>
                            <div className="login-contact">
                                <h6> The PASSWORD has been sent to all credited Buyers and Sellers:</h6>
                                <p>if you didn't receive or if you need more information to utilize the system, please contact us on
                                    <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a>
                                </p>
                            </div>

                        </div>
                    </div>

                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80
                            70 - <a href="segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a></li>
                    </ul>
                </footer>

            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptAuthenticationError show={viewMode === 'authenticationerror'} setViewMode={setViewMode}></PromptAuthenticationError>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>          

        </>
    )
}
