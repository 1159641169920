import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getWebClient } from './helpers/Helpers.js';
import Activity from './pages/Activity';
import Agenda from './pages/Agenda';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import PartnerProfile from './pages/PartnerProfile';
import PartnerAgenda from './pages/PartnerAgenda';
import Print from './pages/Print';
import Profile from './pages/Profile';
import Search from './pages/Search';

export const MainSettingsContext = React.createContext(null);

function App() {

    const [locationAndDates, setLocationAndDates] = useState(null);
    const [readonlySwitchDateTime, setReadonlySwitchDateTime] = useState(new Date('2050-10-30T23:59:59'));
    const [authenticationToken, setAuthenticationToken] = useState(localStorage.getItem("authenticationToken"));

    const client = getWebClient();

    function locationAndDatesGetPromise() {
        return new Promise((resolve) => {
            client.get(`/api/getlocationanddates`).then((response) => {
                resolve(response.data);
            });
        });
    }

    function readOnlySwitchDateTimeGetPromise() {
        return new Promise((resolve) => {
            client.get(`/api/getreadonlyswitchdatetime`).then((response) => {
                resolve(response.data);
            });
        });
    }

    useEffect(() => {

        Promise.all([locationAndDatesGetPromise(), readOnlySwitchDateTimeGetPromise()])

            .then((results) => {

                let locationAndDatesGetPromiseResult = results[0];
                let readOnlySwitchDateTimePromiseResult = results[1];

                setLocationAndDates(locationAndDatesGetPromiseResult.locationAndDates);
                setReadonlySwitchDateTime(new Date(readOnlySwitchDateTimePromiseResult.readOnlySwitchDateTime));

            })

    }, []);

    if (!authenticationToken) {
        return (
            <>
                <div className="wrapper">
                    <MainSettingsContext.Provider value={{ locationAndDates: locationAndDates }}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Login setAuthenticationToken={setAuthenticationToken} />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </MainSettingsContext.Provider>
                </div>
            </>
        );
    }

    if (Date.now() > readonlySwitchDateTime) {

        return (
            <>
                <div className="wrapper">
                    <MainSettingsContext.Provider value={{ locationAndDates: locationAndDates }}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Print readonlySwitchDateTime={readonlySwitchDateTime} />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </MainSettingsContext.Provider>
                </div>
            </>
        );

    } else {

        return (
            <>
                <div className="wrapper">
                    <MainSettingsContext.Provider value={{ locationAndDates: locationAndDates }}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Dashboard setAuthenticationToken={setAuthenticationToken} />}>
                                </Route>
                                <Route path="/activity" element={<Activity />}>
                                </Route>
                                <Route path="/agenda" element={<Agenda />}>
                                </Route>
                                <Route path="/partnerprofile/:partnerid" element={<PartnerProfile />}>
                                </Route>
                                <Route path="/partneragenda/:partnerid" element={<PartnerAgenda />}>
                                </Route>
                                <Route path="/print" element={<Print />}>
                                </Route>
                                <Route path="/profile" element={<Profile />}>
                                </Route>
                                <Route path="/search" element={<Search />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </MainSettingsContext.Provider>
                </div>
            </>
        );

    }


}

export default App;
