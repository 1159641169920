import axios from 'axios';

export function getAuthenticationToken() {
    return localStorage.getItem("authenticationToken");
}

export function getWebClient() {

    let client;

    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        client = axios.create({
            baseURL: 'http://localhost:8000',
            headers: {
                'Content-Type': 'application/json',
                'ApiAuthenticationToken': localStorage.getItem("authenticationToken")
            }
        });
    } else {
        client = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'ApiAuthenticationToken': localStorage.getItem("authenticationToken")
            }
        });
    }

    return client;
}
