import React, { useState, useEffect } from 'react';
import { getWebClient } from '../../helpers/Helpers.js';

export default function PrintDate({ date }) {

    const [agenda, setAgenda] = useState([]);
    const client = getWebClient();

    function agendaGetPromise() {
        return new Promise((resolve) => {
            client.get(`/api/getagenda/${date.id}`).then((response) => {
                resolve(response.data);
            });
        });
    }

    useEffect(() => {

        Promise.all([agendaGetPromise()])

            .then((results) => {
                setAgenda(results[0]);
            })

    }, []);

    if (agenda === []) {

        return ('');

    } else {

        let agendaDays = agenda.map((appointment, index) => {

            if (appointment.abilitato) {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >{appointment.partner ? 'Appointment with ' + appointment.partner.ragioneSociale : 'The appointment is free'}</td>
                            </tr>
                            <tr>
                                <td className="w-260">{appointment.queuedPartner ? 'Queuing with ' + appointment.queuedPartner.ragioneSociale : 'None is queuing'}</td>
                            </tr>
                        </tbody>
                    </table>
                )

            } else {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >You are not enabled for this date/time</td>
                            </tr>
                            <tr>
                                <td className="w-260">Your queue is not enabled for this date/time</td>
                            </tr>
                        </tbody>
                    </table>
                )

            }

        });

        return (
            <>
                <p className="main-heading" style={{ marginTop: 30, marginBottom: 40 }}>{date.label}</p>
                <div className="col-md-12">
                    {agendaDays}
                </div>
            </>
        );

    }



}
