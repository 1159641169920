import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError.js';
import PromptConfirmDelete from './components/PromptConfirmDelete.js';
import PromptConfirmDeleteQueue from './components/PromptConfirmDeleteQueue.js';
import DateTime from './components/DateTime';
import { MainSettingsContext } from '../App.js';

export default function Agenda() {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('waiting');
    const [agenda, setAgenda] = useState();
    const [days, setDays] = useState([{ label: '1ST JANUARY', id: '7B941341-9C20-4A14-B1CA-8A50780D67FC', enabled: true }, { label: '2ND JANUARY', id: '6952AE3A-B7A6-4998-9AD5-4B3F8D825A61', enabled: true }, { label: '3RD JANUARY', id: '325A3625-98F8-4CAB-AF6F-94B378E3BAD6', enabled: true }]);
    const [day, setDay] = useState(0);
    const [appointmentToDeleteId, setAppointmentToDeleteId] = useState();
    const [appointmentQueueToDeleteId, setAppointmentQueueToDeleteId] = useState();
    const navigate = useNavigate();
    const client = getWebClient();

    // TODO Implementare funzioni ...getPromise + Promise.all

    const fetchData = async (dayId) => {

        client.get(`/api/getenableddates`)
            .then(function (response) {

                setDays(response.data.filter(obj => {
                    return obj.enabled === true
                }));

                client.get(`/api/getagenda/${dayId}`)
                    .then(function (response) {
                        setAgenda(response.data);
                        setViewMode('');
                    })
                    .catch(function (error) {
                        console.log(error);
                        setViewMode('error');
                    });

            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    };

    useEffect(() => { fetchData(days[0].id); }, []);

    function handlePreviousDayClick(appointmentid) {
        if (day > 0) {
            let newday = day - 1;
            setDay(newday);
            fetchData(days[newday].id);
        }     
    }

    function handleNextDayClick(appointmentid) {
        if (day < days.length - 1) {
            let newday = day + 1;
            setDay(newday);
            fetchData(days[newday].id);
        }
    }

    function handleAppointmentDeleteClick(appointmentid) {
        setAppointmentToDeleteId(appointmentid);
        setViewMode('confirmdelete');
    }

    function doAppointmentDelete() {

        setViewMode('waiting');

        client.get(`/api/deleteappointment/${appointmentToDeleteId}`)
            .then(function (response) {
                fetchData(days[day].id);
                setViewMode('');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }

    function handleAppointmentQueueDeleteClick(appointmentid) {
        setAppointmentQueueToDeleteId(appointmentid);
        setViewMode('confirmdeletequeue');
    }

    function doAppointmentQueueDelete() {

        setViewMode('waiting');

        client.get(`/api/deleteappointmentqueue/${appointmentQueueToDeleteId}`)
            .then(function (response) {
                fetchData(days[day].id);
                setViewMode('');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }

    let dateTimes = [];

    if (agenda) {
        dateTimes = agenda.map((dateTime, index) => {
            return (<div key={index}><DateTime dateTime={dateTime} handleAppointmentDeleteClick={handleAppointmentDeleteClick} handleAppointmentQueueDeleteClick={handleAppointmentQueueDeleteClick} /></div>)
        });
    }

    return (
        <>
            <section className="buyer-sec ma-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>{locationAndDates}</p>
                        </div>
                    </div>
                </header>
                <div className="container ma-container">
                    <h1 className="main-heading">My Agenda</h1>
                    <p className="text-center">Welcome to your personal appointments’ agenda!</p>
                    <div className="row">
                        <ul>
                            <li>Here you can find all the appointments you have fixed and the names of the operator you are going to meet.</li>
                            <li><b> The agenda will be automatically updated each time you will accept an appointment proposal or a queue request.</b></li>
                            <li> If you want to cancel an appointment already confirmed you can do it by clicking of the icon"trash can". <br /> Here you can also print your agenda.</li>
                        </ul>
                        <div className="col-md-12 text-center">
                            <div className="btn btn-primary main-btn button-fullwidth">
                                <img src="assets/images/iconLeft.svg" alt="" onClick={handlePreviousDayClick} />
                                <button className="btn btn-primary">{days[day].label} appointments</button>
                                <img src="assets/images/iconRight.svg" alt="" onClick={handleNextDayClick} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            {dateTimes}
                        </div>
                        <div className="col-md-12">
                            <div className="text-center print-text" onClick={() => navigate("/print")} style={{ cursor: 'pointer' }}>
                                <img src="assets/images/icon9.svg" alt="" />
                                <span>Printable version</span>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>
            <PromptConfirmDelete show={viewMode === 'confirmdelete'} setViewMode={setViewMode} doAppointmentDelete={doAppointmentDelete}></PromptConfirmDelete>
            <PromptConfirmDeleteQueue show={viewMode === 'confirmdeletequeue'} setViewMode={setViewMode} doAppointmentQueueDelete={doAppointmentQueueDelete}></PromptConfirmDeleteQueue>

        </>
    );

}