import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError.js';
import { MainSettingsContext } from '../App.js';

export default function Dashboard({ setAuthenticationToken }) {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('waiting');

    const [profile, setprofile] = useState(
        {
            company: "",
            city: "",
            country: "",
            typologyId: "",
            personInCharge: "",
            telephone: "",
            email: "",
            website: "",
            description: ""
        }
    );

    const navigate = useNavigate();

    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/getprofile`)
            .then(function (response) {
                setprofile(response.data);
                setViewMode('');
                window.scrollTo(0, 0);
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }, []);

    function handleActivityClick() {
        navigate("/activity");
    }

    function handleAgendaClick() {
        navigate("/agenda");
    }

    function handleProfileClick() {
        navigate("/profile");
    }
    function handleSearchClick() {
        navigate("/search");
    }

    function handleLogoutClick() {
        setAuthenticationToken(null);
        localStorage.removeItem("authenticationToken");
        sessionStorage.removeItem("searchParams");
        sessionStorage.removeItem("searchResults");
    }

    return (
        <>

            <section className="main-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>{locationAndDates}</p>
                        </div>
                    </div>
                </header>
                <div className="main-container">
                    <h1 className="main-heading">{profile.company}</h1>
                    <p className="main-pera">Welcome in your private area!</p>
                    <ul className="btn-list">
                        <li> <button className="btn btn-secondary pro-btn" onClick={handleAgendaClick}>Your Agenda</button> </li>
                        <li> <button className="btn btn-secondary pro-btn" onClick={handleActivityClick}>Your Activity</button> </li>
                        <li> <button className="btn btn-secondary pro-btn" onClick={handleProfileClick}>Modify your profile</button> </li>
                        <li> <button className="btn btn-primary buyer-btn" onClick={handleSearchClick}>Partners’s Search</button> </li>
                    </ul>
                    <div className="main-card">
                        <h1>From this page you will be able to:</h1>
                        <div className="row">
                            <div className="col-md-12 mb-3 mt-3">
                                <h6>MONITOR YOUR AGENDA</h6>
                                <p>Clicking on the button “your agenda”, it will be possible to monitor appointments, confirm them and evaluate new proposals</p>
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                                <h6>SEARCH BUYERS</h6>
                                <p>Clicking on the button “Buyers’ Search”, it will be possible to see the complete and always updated list of buyers or search them by typology or geographic area</p>
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                                <h6>ACCESS BUYERS PROFILE</h6>
                                <p>Clicking on the name of the chosen buyer you will have access to his profile and you will have the possibility of asking additional information or fixing an appointment.</p>
                            </div>
                        </div>
                        <div className="col-md-12 text-center" style={{ marginTop: 20 }}>
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={handleLogoutClick}>Log out</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>

        </>
    );

}