import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError';
import PromptUpdateSuccessful from './components/PromptUpdateSuccessful';
import { MainSettingsContext } from '../App.js';

export default function Profile() {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('waiting');
    const [profile, setprofile] = useState(
        {
            company: '',
            city: '',
            country: '',
            typologyId: 0,
            personInCharge: '',
            telephone: '',
            email: '',
            website: '',
            description: '',
            seller: null,
            buyer: null
        }
    );

    const [tipologies, setTipologies] = useState([]);
    const navigate = useNavigate();
    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/gettypologies`)
            .then(function (typologiesResponse) {

                setTipologies(typologiesResponse.data);

                client.get(`/api/getprofile`)
                    .then(function (profileResponse) {
                        setprofile(profileResponse.data);
                        setViewMode('')
                    })
                    .catch(function (error) {
                        console.log(error);
                        setViewMode('error')
                    })
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error')
            });

    }, []);

    function handleOnChange() {
        setprofile(
            {
                company: document.getElementById('txtCompany').value,
                city: document.getElementById('txtCity').value,
                country: document.getElementById('txtCountry').value,
                typologyId: document.getElementById('selTypologies').value,
                typology: null,
                personInCharge: document.getElementById('txtPersonInCharge').value,
                telephone: document.getElementById('txtTelephone').value,
                email: document.getElementById('txtEmail').value,
                website: document.getElementById('txtWebsite').value,
                description: document.getElementById('txtDescription').value,
            }
        );
    }

    function formHasErrors() {

        let errors = false;

        document.getElementById('txtCompany').classList.remove("is-invalid");
        document.getElementById('txtCity').classList.remove("is-invalid");
        document.getElementById('txtCountry').classList.remove("is-invalid");
        document.getElementById('txtPersonInCharge').classList.remove("is-invalid");
        document.getElementById('txtTelephone').classList.remove("is-invalid");
        document.getElementById('txtEmail').classList.remove("is-invalid");
        document.getElementById('txtWebsite').classList.remove("is-invalid");
        document.getElementById('txtDescription').classList.remove("is-invalid");

        if (profile.company.length < 1) { document.getElementById('txtCompany').classList.add("is-invalid"); errors = true; }
        if (profile.city.length < 1) { document.getElementById('txtCity').classList.add("is-invalid"); errors = true; }
        if (profile.country.length < 1) { document.getElementById('txtCountry').classList.add("is-invalid"); errors = true; }
        if (profile.personInCharge.length < 1) { document.getElementById('txtPersonInCharge').classList.add("is-invalid"); errors = true; }
        if (profile.telephone.length < 1) { document.getElementById('txtTelephone').classList.add("is-invalid"); errors = true; }
        if (profile.email.length < 1) { document.getElementById('txtEmail').classList.add("is-invalid"); errors = true; }
        if (profile.website.length < 1) { document.getElementById('txtWebsite').classList.add("is-invalid"); errors = true; }
        if (profile.description.length < 1) { document.getElementById('txtDescription').classList.add("is-invalid"); errors = true; }

        return errors;
    };

    function handleUpdateClick() {

        if (!formHasErrors()) {

            client.post('/api/setprofile', profile)
                .then(function (response) {
                    setViewMode('updatesuccess')
                })
                .catch(function (error) {
                    console.log(error);
                    setViewMode('error')
                });

        }

    }

    return (
        <>

            <section className="buyer-sec profile-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>{locationAndDates}</p>
                        </div>
                    </div>
                </header>
                <div className="buyer-container profile-container">
                    <h1 className="main-heading">Update my profile</h1>
                    <div className="row">
                        <ul>
                            <li className="">
                                <span className="co-btn2">Company</span>
                                <span style={{ marginLeft: 10 }}><input type="text" id="txtCompany" maxLength="250" placeholder="Enter name" className="form-control" value={profile.company} onChange={handleOnChange} /> </span>
                            </li>                            
                            <li className="">
                                <span className="co-btn2">City</span>
                                <span><input type="text" id="txtCity" maxLength="100" placeholder="Enter city" className="form-control" value={profile.city} onChange={handleOnChange} /></span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Country</span>
                                <span><input type="text" id="txtCountry" maxLength="100" placeholder="Enter country" className="form-control" value={profile.country} onChange={handleOnChange} /></span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Typology</span>
                                <span>
                                    <select id="selTypologies" value={profile.typologyId} onChange={handleOnChange}>
                                        {tipologies.map((typology, index) => {
                                            return (<option id="selTypologies" key={typology.id} value={typology.id}>{typology.name}</option>)
                                        }) }
                                    </select>
                                </span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Person in charge</span>
                                <span><input type="text" id="txtPersonInCharge" maxLength="50" placeholder="Enter name person" className="form-control" value={profile.personInCharge} onChange={handleOnChange} /></span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Telephone</span>
                                <span><input type="text" id="txtTelephone" maxLength="50" placeholder="Enter telephone number" className="form-control" value={profile.telephone} onChange={handleOnChange} /></span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Email</span>
                                <span><input type="text" id="txtEmail" maxLength="64" placeholder="Enter email" className="form-control" value={profile.email} onChange={handleOnChange} /></span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Website</span>
                                <span><input type="text" id="txtWebsite" maxLength="150" placeholder="Enter website" className="form-control" value={profile.website} onChange={handleOnChange} /></span>
                            </li>
                        </ul>
                        <div className="col-md-12 des-sec">
                            <h3>Description</h3>
                            <textarea id="txtDescription" maxLength="3500" className="form-control" placeholder="Enter a description" value={profile.description} onChange={handleOnChange}></textarea>
                        </div>
                        <div className="col-md-12 text-center">
                            <button className="btn btn-primary appointment-btn" onClick={handleUpdateClick}>Update your data</button>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>
            <PromptUpdateSuccessful show={viewMode === 'updatesuccess'} setViewMode={setViewMode}></PromptUpdateSuccessful>

        </>
    );


}