import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';
import PromptError from './components/PromptError';
import { MainSettingsContext } from '../App.js';

export default function Search() {

    const { locationAndDates } = useContext(MainSettingsContext);

    const [viewMode, setViewMode] = useState('waiting');
    const [partners, setPartners] = useState();
    const [countries, setCountries] = useState([]);
    const [tipologies, setTipologies] = useState([]);
    const navigate = useNavigate();
    const client = getWebClient();

    useEffect(() => {

        client.get(`/api/getpartnertypologies`)
            .then(function (typologiesResponse) {

                setTipologies(typologiesResponse.data);

                client.get(`/api/getpartnercountries`)
                    .then(function (profileResponse) {
                        setCountries(profileResponse.data);

                        if (sessionStorage.getItem("searchResults")) {
                            let searchParams = JSON.parse(sessionStorage.getItem("searchParams"));
                            document.getElementById('selTypology').value = searchParams.typologyId;
                            document.getElementById('selCountry').value = searchParams.country;
                            document.getElementById('txtName').value = searchParams.name;
                            setPartners(JSON.parse(sessionStorage.getItem("searchResults")));
                        }

                        setViewMode('');
                    })
                    .catch(function (error) {
                        console.log(error);
                        setViewMode('error')
                    })
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error')
            });

    }, []);



    function handleSearchClick() {

        setViewMode('waiting');

        client.post('/api/getpartners',
            {
                typologyId: document.getElementById('selTypology').value,
                country: document.getElementById('selCountry').value,
                name: document.getElementById('txtName').value
            })
            .then(function (response) {
                sessionStorage.setItem("searchParams", JSON.stringify(
                    {
                        typologyId: document.getElementById('selTypology').value,
                        country: document.getElementById('selCountry').value,
                        name: document.getElementById('txtName').value
                    }
                ));
                sessionStorage.setItem("searchResults", JSON.stringify(response.data));
                setPartners(response.data);
                setViewMode('');
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });
    }

    let partnerRows;

    if (partners) {

        partnerRows = partners.map((partner, index) => {
            return (
                <tr key={index}>
                    <td><a href={'/partnerprofile/' + partner.id}>{partner.ragioneSociale}</a></td>
                    <td>{partner.localita}</td>
                    <td>{partner.nazione}</td>
                </tr>
            );
        });

    };

    return (

        <>

            <section className="buyer-sec search-sec">
                <header className="header">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="assets/images/logo.svg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <h2>Workshop Agenda</h2>
                            <p>{locationAndDates}</p>
                        </div>
                    </div>
                </header>
                <div className="buyer-container search-container">
                    <h1 className="main-heading">Partner Search</h1>
                    <p>Here is the list of the accredited companies that will be present at the Workshop.</p>
                    <div className="row">
                        <ul>
                            <li className="">
                                <span className="co-btn2">Type</span>
                                <span>
                                    <select id="selTypology">
                                        <option value=''>-</option>
                                        {tipologies.map((typology, index) => {
                                            return (<option key={typology.id} value={typology.id}>{typology.name}</option>)
                                        })}
                                    </select>
                                </span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Country</span>
                                <span>
                                    <select id="selCountry">
                                        <option value=''>-</option>
                                        {countries.map((country, index) => {
                                            return (<option key={country.id} value={country.id}>{country.name}</option>)
                                        })}
                                    </select>
                                </span>
                            </li>
                            <li className="">
                                <span className="co-btn2">Name</span>
                                <span><input id="txtName" type="text" name="" placeholder="Enter name" className="form-control" /></span>
                            </li>
                        </ul>
                        <div className="col-md-12">
                            <center>
                                <button className="btn btn-primary" onClick={handleSearchClick}>Search</button>
                            </center>
                        </div>
                        <div className="col-md-12">
                            <table className="table search-table">
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Place</th>
                                        <th>Country</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partnerRows}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="back-btn">
                                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <ul>
                        <li><b> © 2024 - PromAx Comunication SA </b>- Via Purasca 28 - 6988 Ponte Tresa - CH - Tel. +41 (0)91 611 80 70 - <a href="mailto:segreteria@promax.ch"> segreteria@promax.ch </a> - <a href="https://www.promax.ch"> www.promax.ch </a>
                        </li>
                    </ul>
                </footer>
            </section>

            <Spinner show={viewMode === 'waiting'}></Spinner>
            <PromptError show={viewMode === 'error'} setViewMode={setViewMode}></PromptError>


        </>
    );


}